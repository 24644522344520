@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Serif&display=swap);
.loader {
  z-index: 9000;
  left: 0px;
  align-items: center;
  background-color: #0000007a;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: fixed;
  top: 0;
}

.loader-wheel {
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
  border: 2px solid rgba(30, 30, 30, 0.5);
  border-left: 4px solid #fff;
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 50px;
}

.loader-text {
  color: #fff;
  font-family: arial, sans-serif;
}

.loader-text:after {
  content: 'Loading';
  -webkit-animation: load 2s linear infinite;
          animation: load 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes load {
  0% {
    content: 'Loading';
  }
  33% {
    content: 'Loading.';
  }
  67% {
    content: 'Loading..';
  }
  100% {
    content: 'Loading...';
  }
}

@keyframes load {
  0% {
    content: 'Loading';
  }
  33% {
    content: 'Loading.';
  }
  67% {
    content: 'Loading..';
  }
  100% {
    content: 'Loading...';
  }
}

.user-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 115%;
  /* identical to box height, or 14px */

  text-align: center;
  letter-spacing: 0.023em;
}

.user-card .user-fullname {
  margin-right: 10px;
}

.user-card span {
  color: #484848;
}
.user-card a {
  color: #faba28;
}

/* browsers fixes */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.textbox-container {
  height: 54px;
  width: 100%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  background: #fbfbfb;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
}

.textbox-container .left-icon {
  width: 16px;
}

.textbox-container .prefix-phone-number {
  width: 50px;
  height: 100%;
  line-height: 4 !important;
  color: #a9a9a9;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  border-right: 1px solid #eaeaea;
  padding-right: 10px;
  margin-left: 10px;
}

.textbox-container input {
  flex-grow: 1;
  width: 100%;
  background-color: transparent;
  border: none !important;
  outline: none !important;
  margin-left: 14px;
  margin-right: 14px;
}

.textbox-container input:focus-within,
.textbox-container input:focus-visible,
.textbox-container input:hover,
.textbox-container input:focus {
  border: none !important;
}

.textbox-container input:read-only {
  color: #737373;
}
.textbox-container input::-webkit-input-placeholder {
  color: #a9a9a9;
}
.textbox-container input:-ms-input-placeholder {
  color: #a9a9a9;
}
.textbox-container input::placeholder {
  color: #a9a9a9;
}

.btn-default {
  height: 54px;
  border-radius: 6px;

  border: 1px solid #b7b0b0;
  background: #333333;
  color: #ffffff;
}

.btn-main {
  height: 54px;
  border-radius: 6px;
  width: 100%;
  border: none !important;
  background: #333333;
  color: #ffffff;
}

.btn-main:disabled {
  color: #c3a96e;
  border: none !important;
  background: #fff4dc;
}
.auth-container {
    margin-top: 80px;
}

.auth-container .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 63px;
}

.auth-container .logo img {
    padding: 20px 0;
}

.auth-container .login-form-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.header-menu-container {
    background-color: #333333;
    display: flex;
    height: 83px;
}

.header-menu-container-offline,
.header-menu-container-offline .left-section,
.header-menu-container-offline .right-section,
.header-menu-container-offline .center-section {
    background-color: #be0000;
}


.header-menu-container button {
    background-color: #333333;
    border: 0;
}

.header-menu-container .left-section {
    flex: 0.1 1;
}

.header-menu-container .right-section {
    flex: 0.1 1;
}

.header-menu-container .center-section {
    display: flex;
    flex: 0.8 1;
    align-items: center;
    justify-content: center;
}

.header-menu-container .center-section label {
    color: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;
    margin-left: 5px;
}


@media screen and (max-width: 600px) {
    .header-menu-container .left-section {
        flex: 0.2 1;
    }

    .header-menu-container .right-section {
        flex: 0.2 1;
    }

    .header-menu-container .center-section {

        flex: 0.6 1;
    }
}
.offcanvas-title {
    font-family: Poppins;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #9F9F9F;
}

.list-group {
    display: unset !important;
}

.offcanvas-header {
    padding: 1rem 2rem !important;
}

.list-group-item {
    border: 0 !important;

    font-weight: 500 !important;
}

.logout-btn {
    padding: 1rem 0.5rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    bottom: 20px;

    color: #F15E5E;
    border: 0;
    background-color: transparent;
    position: absolute;
    text-align: left;
    width: 80%;
}

.logout-logo {
    margin-left: 10px;
    color: #F15E5E;
}

@media screen and (max-width: 600px) {
    .offcanvas-start {
        width: 300px !important;
    }
}
.confrimpopup .modal-body {
    width: 100%;
    padding: 24px 15px !important;
    text-align: left;
}

.confrimpopup .modal-body h2 {
    width: 100%;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 115%;
    /* identical to box height, or 21px */

    letter-spacing: 0.023em;

    color: #000000;
    margin-bottom: 25px;
}

.confrimpopup .modal-body p {
    margin-top: 20px;
}

.confrimpopup .btn-group {
    width: 100%;
    height: auto;
    margin-top: 50px;
    display: flex;
}

.confrimpopup .btn-yes,
.confrimpopup .btn-no {
    height: 54px;
    border-radius: 6px;
}

.confrimpopup .btn-yes {
    background: #ffbf2b;
    border-radius: 6px;
    margin-left: 10px;
    height: 54px;
    border: none !important;
    flex: 1 1;
}

.confrimpopup .btn-no {
    width: 120px;
    border: 1px solid #b7b0b0;
    box-sizing: border-box;
    background: #ffffff;
    color: #000
}

.confrimpopup .btn-yes:disabled {
    color: #c3a96e;
    border: none !important;
    background: #fff4dc;
}
.setting-basic-box .modal-header div {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    flex-grow: 1;
}

.setting-basic-box .modal-sm {
    max-width: 400px !important;
}


@media (min-width: 576px) {
    .setting-basic-box .modal-sm {
        max-width: 400px !important;
    }
}

.setting-basic-box .modal-body,
.setting-basic-box .modal-header {
    background: #F0F0F0;
}
/* Customize the label (the container) */
.checkbox-container {
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}

.checkbox-container.bordered {
  box-shadow: inset 0px -1px 0px #f2f2f2;
}
.checkbox-container:last-child {
  box-shadow: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-container .checkmark {
  position: absolute;
  top: 10px;
  left: 8px;
  height: 18px;
  border-radius: 4px;
  width: 18px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container .checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #15cc5e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-container .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.setting-basic-container .checkbox-container {
    background: #E4E4E4;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
}

.setting-basic-container .checkbox-container .checkmark {
    left: unset;
    right: 8px;
}

.setting-basic-container .checkbox-container label {
    flex-grow: 1;
}

.setting-basic-container .checkbox-container .checkbox-input-container {
    display: flex;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
}

.setting-basic-container .checkbox-container .checkbox-input-container input {
    height: 18px;
    width: 18px;
    border-radius: 4px;
}

.setting-basic-container .checkbox-container .checkbox-input-container input[type="checkbox"] {
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
}

.setting-basic-container .textbox-section {
    display: flex;
}

.setting-basic-container .textbox-section .textbox-item {
    flex: 1 1;
    width: 50px;
    padding: 5px;
}

.setting-basic-container .textbox-section .textbox-item .textbox-section-container {
    width: 100%;
}

.setting-basic-container .textbox-section .textbox-item label {
    color: #9F9F9F;
    font-size: 12px;
    margin-bottom: 5px;
}

.setting-basic-container .textbox-section .textbox-item .textbox-section-container input:-ms-input-placeholder {

    color: #CECECE;
}

.setting-basic-container .textbox-section .textbox-item .textbox-section-container input:placeholder-shown {

    color: #CECECE;
}


.setting-basic-container .textbox-section .textbox-item .textbox-section-container input {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    background: #FFFFFF;
    color: #000;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
}

.setting-basic-container .btn-done {
    margin-top: 20px;
    width: 100%;
    height: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    background: #333333;
    border-radius: 6px;
}
.language-basic-box .modal-header div {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    flex-grow: 1;
}

.language-basic-box .modal-sm {
    max-width: 400px !important;
}


@media (min-width: 576px) {
    .language-basic-box .modal-sm {
        max-width: 400px !important;
    }
}

.language-basic-box .modal-body,
.language-basic-box .modal-header {
    background: #F0F0F0;
}
.radio-container {
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #070707;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}
.radio-container.bordered {
  box-shadow: inset 0px -1px 0px #f2f2f2;
}
.radio-container:last-child {
  box-shadow: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-container .checkmark {
  position: absolute;
  top: 10px;
  left: 8px;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #15cc5e;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-container .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.language-basic-container .radio-container {
    background: #E4E4E4;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
}

.language-basic-container .radio-container .checkmark {
    left: unset;
    right: 8px;
}

.language-basic-container .radio-container label {
    flex-grow: 1;
}

.language-basic-container .radio-container .checkbox-input-container {
    display: flex;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
}

.language-basic-container .radio-container .checkbox-input-container input {
    height: 18px;
    width: 18px;
    border-radius: 4px;
}

.language-basic-container .radio-container .checkbox-input-container input[type="checkbox"] {
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
}

.language-basic-container .textbox-section {
    display: flex;
}

.language-basic-container .textbox-section .textbox-item {
    flex: 1 1;
    width: 50px;
    padding: 5px;
}

.language-basic-container .textbox-section .textbox-item .textbox-section-container {
    width: 100%;
}

.language-basic-container .textbox-section .textbox-item label {
    color: #9F9F9F;
    font-size: 12px;
    margin-bottom: 5px;
}

.language-basic-container .textbox-section .textbox-item .textbox-section-container input:-ms-input-placeholder {

    color: #CECECE;
}

.language-basic-container .textbox-section .textbox-item .textbox-section-container input:placeholder-shown {

    color: #CECECE;
}


.language-basic-container .textbox-section .textbox-item .textbox-section-container input {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    background: #FFFFFF;
    color: #000;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
}

.language-basic-container .btn-done {
    margin-top: 20px;
    width: 100%;
    height: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    background: #333333;
    border-radius: 6px;
}
* {
    font-family: "Poppins", sans-serif;
}

body {
    background-color: #E5E5E5;
}

.alert {
    font-size: 9pt;
    padding: 0.25rem 0.5em;
}
.orders-container {}

.orders-navbars {}

.orders-container .parent {
    padding-top: 20px;
}

.orders-container .nav {
    flex-wrap: nowrap !important;
}

.orders-container .card-detail-section {
    /* border: 1px solid #d6d6d6; */
}

.orders-container .in-progress-section {
    border: 1px solid #d6d6d6;
}

.orders-container .in-progress-section .title {
    background: #dadada !important;
    border-radius: 6px !important;
    padding: 17px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */


    color: #686D74;
}
.orders-navbars .nav {

    background: #dadada !important;
    border-radius: 6px !important;
    padding: 5px;
}

.orders-navbars .nav .nav-item {
    flex: 1 1;
    text-align: center;
}

.orders-navbars .nav .nav-item a {
    color: #686D74;
}

.orders-navbars .nav .nav-item a.active {
    background: #FFFFFF !important;
    color: #373C41;
}
.card-info-container {
    width: 100%;
    /* height: 95px; */
    background-color: #6dc287;
    border-radius: 6px;
    margin-top: 10px;
    padding: 10px 10px 6px;
    color: #fff;
    cursor: pointer;
}

.card-info-container:last-child {
    margin-bottom: 10px;
}

.card-info-container .top,
.card-info-container .middle,
.card-info-container .bottom {
    display: flex;
}

.card-info-container .top {
    font-size: 14px;
}

.card-info-container .top .left b {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.card-info-container .middle {
    margin: 3px 0;
    font-size: 14px;
    color: #CCF4D8;
}

.card-info-container .bottom {
    margin-left: -10px !important;
    margin-right: -10px;
    height: 34px;
    padding: 10px 10px;
    font-size: 12px;
    margin-top: 8px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.card-info-container .top div,
.card-info-container .middle div,
.card-info-container .bottom div {
    flex: 1 1;
}

.card-info-container .bottom .left b {
    padding: 2px 5px;
    background-color: #F6EFA8;
    border-radius: 5px;
    color: #655C03;
    font-weight: 500;
}

.card-info-container .top div:last-child,
.card-info-container .middle div:last-child,
.card-info-container .bottom div:last-child {
    text-align: right;
    min-width: -webkit-max-content;
    min-width: max-content;
}

.card-info-container .bottom {
    background-color: #5DAC75;
}



.in-progress {
    background-color: #ffffff;
    color: #000;
}

.in-progress .middle {
    color: #000;
}

.in-progress .bottom {
    background: #F8F8F8;
}

.in-progress .bottom .left {
    color: #9F9F9F
}


.in-progress .bottom .right {
    /* color: #E15C5C */
    color: #9F9F9F;
}

.in-progress .top .right button {
    background: #333333;
    border-radius: 6px;
    border: 0;
    color: #ffffff;
    padding: 10px
}

.in-progress .bottom .left b {

    padding: unset;
    background: unset;
    border-radius: unset;
}


.selected {
    background-color: #4389F3;
    color: #ffffff;
    cursor: unset;
}

.selected .middle {
    color: #C0C6C2
}

.selected .bottom, .selected .bottom.store-type-pos {
    background-color: #3174D9;
}



.selected .bottom .right,
.selected .bottom .left {
    color: #F1F1F1;
}

.in-progress.selected .bottom .left b {
    color: #F1F1F1;
    padding: unset;
    background: unset;
    border-radius: unset;
}

.selected .bottom .left b {
    background-color: #fff;
    color: #3174D9;
}

.selected .top .right button {
    background: #ffffff;
    border-radius: 6px;
    border: 0;
    color: #333333;
    padding: 10px
}
.card-info-container.store-type-pos{
    background-color: #ffc40d;
}
.bottom.store-type-pos{
    background-color: #e3a21a;
}
.order-list-container {
    overflow: auto;
    height: 580px;
}

.order-list-container .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.order-list-container .order-list-loading,
.order-list-container .order-list-more-data-loading {
    display: inline-block;
    align-items: center;
    justify-items: center;
    text-align: center;
    width: 100%;
    padding-top: 100px;
}

.order-list-container .order-list-more-data-loading {

    padding-top: 20px;
}

.order-list-container .empty-list img {
    max-width: 250px;
}

.order-list-container .inprogressempty img {
    max-width: 250px;
    margin-top: 55px;
}

.order-list-container .empty-list p {
    margin-top: 50px;
}

@media screen and (max-width: 992px) {
    .order-list-container {
        overflow: auto;
        height: 170px;
    }

    .order-list-container .empty-list {
        margin-top: 20px;
    }

    .order-list-container .empty-list img,
    .order-list-container .inprogressempty img {
        max-width: 80px;
    }

    .order-list-container .empty-list p {
        margin-top: 10px;
    }


    .order-list-container .inprogressempty img {
        margin: 0;
    }

}
.card-detail-container {
    /* height: 650px;
    overflow: auto; */
}

.card-detail-container .scrollable {
    height: 330px;
    overflow: auto;
}

.card-detail-container .scrollable-alone {
    height: 430px;
    overflow: auto;
}

@media screen and (max-height: 570px) {
    .card-detail-container .scrollable {
        height: 260px;
        overflow: auto;
    }

    .card-detail-container .scrollable-alone {
        height: 430px;
        overflow: auto;
    }
}

@media screen and (min-height: 570px)and (max-height: 670px) {
    .card-detail-container .scrollable {
        height: 330px;
        overflow: auto;
    }

    .card-detail-container .scrollable-alone {
        height: 430px;
        overflow: auto;
    }
}



@media screen and (min-height: 670px) and (max-height: 785px) {
    .card-detail-container .scrollable {
        height: 400px;
        overflow: auto;
    }

    .card-detail-container .scrollable-alone {
        height: 530px;
        overflow: auto;
    }
}

@media screen and (min-height: 785px) {
    .card-detail-container .scrollable {
        height: auto;
        overflow: auto;
    }

    .card-detail-container .scrollable-alone {
        height: auto;
        overflow: auto;
    }
}


.card-detail-container .card-loading {
    display: inline-block;
    align-items: center;
    justify-items: center;
    text-align: center;
    width: 100%;
    padding-top: 50px;
}


.fixed {
    background-color: #F6F6F6;
    height: 135px;
    padding: 10px;
}

.card-detail-container .top {
    display: flex;
    margin-bottom: 5px;
}


.card-detail-container .top .right {
    justify-content: flex-end;
    flex: 0.5 1;
    display: flex;
}
.card-detail-container .top .right button.btn-print
{
    border-color: black;
    color: black;    
}
.card-detail-container .top .right button {
    border: 2px solid #F84E4E;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 13px 29px;
    color: #F84E4E;
    background-color: transparent;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-left: 5px;
}

.card-detail-container .top .left {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 50px;
    flex: 1.5 1;
}

.card-detail-container .middle {
    margin-top: 10px;
    background: #FFFFFF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 10px 8px
}



.card-detail-container .middle p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */


    color: #333333;
}

.card-detail-container .middle .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
}

.card-detail-container .middle .external-id
{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.card-detail-container .middle .warning {
    background: #F7EFA8;
    border-radius: 6px;
    min-height: 27px;
    color: #655C03;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    padding: 2px 7px
}

.card-detail-container .middle:nth-child(3) {
    margin-top: 0;
    background: #F8F8F8;
    border-radius: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.card-detail-container .bottom {
    margin-top: 10px;
}

.card-detail-container .bottom .note {
    height: auto;
    min-height: 50px;
    background-color: #e0e0e0;
    border-radius: 6px;
    padding: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */


    color: #686D74;
    margin-bottom: 10px;
}

.card-detail-container .items-container {

    overflow: auto;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.card-detail-container .items-container.filled {
    padding: 15px 10px;
    border: 1px solid rgb(0 0 0 / 5%);
}

.card-detail-container .cartitem {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}

.card-detail-container .cartitem .name {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.023em;
    font-weight: 500;
    color: #484848;
    flex-grow: 1;
    padding: 0 11px;
    width: 100%;
}

.card-detail-container .cartitem .name .options {
    display: flex;
    flex-direction: column;
}

.card-detail-container .cartitem .name .options span {
    font-size: 10px;
    line-height: 15px;
    color: #737373;
}

.card-detail-container .cartitem .price {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.023em;
    color: #484848;
}

.card-detail-container .cartitem .quantity {
    display: flex;
    justify-content: center;
    width: 15px;
    margin: 0 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.023em;
    color: #484848;
}

.card-detail-container .summary {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #F8F8F8;
}

.card-detail-container .summary .summary-row {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    margin-bottom: 13px;
}

.card-detail-container .summary .summary-row span {
    font-size: 12px;
    line-height: 115%;
    letter-spacing: 0.023em;
    color: #737373;
}

.card-detail-container .summary-total {
    background-color: #F2F2F2;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.card-detail-container .summary .summary-row.total span {
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.023em;
    color: #484848;
}

.card-detail-container .summary-total .summary-row.total {
    margin-bottom: 0;
}


.card-detail-container .fixed .delivery-time {
    width: 100%;
    height: 48px;
    background-color: #E4E4E4;
    border-radius: 6px;
    margin-bottom: 15px;
}



.card-detail-container .fixed .delivery-time .nav-pills {
    margin: 0 !important;
    padding: 3px !important;
    font-size: 12px;
    border: 0 !important;
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    display: inline-block;
    padding-right: 108px !important;
}

.card-detail-container .fixed .delivery-time .nav-pills::-webkit-scrollbar {
    height: 7px;
}

.card-detail-container .fixed .delivery-time .nav-pills .nav-item {
    border: 0;
    display: inline-block;
}

.card-detail-container .fixed .delivery-time .nav-pills .nav-link {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    border: 0;
    border-radius: 6px;
    padding: 13px 10px 12px 10px;
    margin-left: 3px;
    /* background: linear-gradient(0deg, #fbfbfb, #fbfbfb), #fbfbfb; */
    border-radius: 5px;
    color: #686D74;
    width: 70px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 115%;
    /* identical to box height, or 15px */

    letter-spacing: 0.023em;
}

.card-detail-container .fixed .delivery-time .nav-pills .nav-link.active {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    background: linear-gradient(0deg, #fbfbfb, #fbfbfb), #fbfbfb;
    color: #373C41;
}

.card-detail-container .fixed .delivery-time .nav-pills .nav-item:last-child {
    display: inline-flexbox !important;
    position: fixed;
    right: 23px;
}

.card-detail-container .fixed .delivery-time .nav-pills .nav-item:last-child .nav-link {

    border: 1.5px solid #333333;

    background: #353535;
    color: #fff;
    border-radius: 6px;
    width: 103px;
    text-align: center;

    padding: 13px 10px 12px 5px;
}

.card-detail-container .fixed .btn-section {
    display: flex;
}

.card-detail-container .fixed .btn-section button,
.card-detail-container .fixed .btn-section label {
    flex: 1 1;
    font-size: 12px;
    text-align: center;
}

.card-detail-container .fixed .btn-section label {
    flex: 0.5 1;
    padding-top: 10px;
    margin-right: 10px;
    font-size: 20px;
}

.card-detail-container .fixed .btn-section .other-time {
    flex: 0.5 1;
    border: 1.5px solid #333333;
    box-sizing: border-box;
    border-radius: 6px;
    background: transparent;
    padding: 15px;
    margin-right: 10px;
}

.card-detail-container .fixed .btn-section .order-delivery-confirm {
    background: #6DC287;
    border: 0;
    border-radius: 6px;
    padding: 15px 5px;
    text-align: center;
    color: #fff
}

.card-detail-container .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 128px;
}

.card-detail-container .empty-list img {
    max-width: 250px;
}


.card-detail-container .empty-list p {
    margin-top: 50px;
}

@media screen and (max-width: 993px) {
    .fixed {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
    }

    /* .card-detail-container .scrollable {
        padding-bottom: 100px;
    } */

    /* .card-detail-container .fixed .delivery-time .nav-pills .nav-item:last-child {
        bottom: 95px;
    } */

}
.tipspopup .btn-cancel {
    flex: 1 1;
    height: 45px;
    border-radius: 6px;
}


.tipspopup .btn-cancel {
    border: 2px solid #333333;
    box-sizing: border-box;
    background: transparent;
}

.tipspopup .modal-content {

    background: #F1F1F1;
}

.tipspopup .modal-body {
    width: 100%;
    padding: 14px 15px !important;
    text-align: left;
}

.tipspopup .modal-body .textbox-container {
    padding-left: 10px;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    font-size: 12px;
}

.tipspopup .modal-body .textbox-container input {
    margin-left: unset;
    margin-right: unset;
    padding: unset;
    resize: none;
    flex: 1 1;
    width: 100%;
    text-align: left;
    font-size: 14px;
    padding-right: 3px;
    box-sizing: border-box;
    border-radius: 6px;
}


.tipspopup .modal-body .alert {
    text-align: left !important;
    font-size: 9pt;
}

.tipspopup .modal-body h2 {

    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-size: 12px;

    color: #9F9F9F;
    padding: 0 0 0 10px
}


.tipspopup .modal-body .btn-group {
    width: 100%;
    height: auto;
    display: flex;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.tipspopup .modal-body .btn-done {
    margin-left: 10px;
    height: 45px;
    flex: 1.1 1;
    border: 2px solid #6DC287;
    box-sizing: border-box;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #6DC287;
    line-height: 18px;
}

.tipspopup .modal-body p {
    margin-top: 20px;
}

@media screen and (max-width:768px) {
    .tipspopup {
        position: -webkit-sticky !important;
        position: sticky !important;
        bottom: 0 !important;
        left: 0 !important;
        z-index: 1055 !important;
        width: 100% !important;
        height: unset !important;
        overflow-x: unset !important;
        overflow-y: unset !important;
        outline: 0 !important;
    }

    .tipspopup .modal-dialog {
        margin: 0 !important;
    }

    .tipspopup .modal-content {
        border-radius: 0 !important;
    }

    .modal-sm {
        max-width: 100%;
    }
}
.rejectOrderPopup .btn-cancel {
    flex: 1 1;
    height: 45px;
    border-radius: 6px;
}


.rejectOrderPopup .btn-cancel {
    border: 2px solid #333333;
    box-sizing: border-box;
    background: transparent;
}


.rejectOrderPopup .modal-body {
    width: 100%;
    padding: 14px 15px !important;
    text-align: left;
}

.rejectOrderPopup .modal-body .textarea-container {
    padding-left: 0;
    padding-right: 0;
    background: unset;
    border: unset;
    font-size: 12px;
}

.rejectOrderPopup .modal-body .textarea-container textarea {
    margin-left: unset;
    margin-right: unset;
    padding: unset;
    resize: none;
}

.rejectOrderPopup .modal-body .alert {
    text-align: left !important;
    font-size: 9pt;
}

.rejectOrderPopup .modal-body h2 {
    width: 100%;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 115%;
    /* identical to box height, or 21px */

    letter-spacing: 0.023em;

    color: #000000;
    margin-bottom: 25px;
}


.rejectOrderPopup .modal-body .btn-group {
    width: 100%;
    height: auto;
    margin-top: 50px;
    display: flex;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.rejectOrderPopup .modal-body .btn-done {
    margin-left: 10px;
    height: 45px;
    flex: 1.1 1;
    border: 2px solid #F67676;
    box-sizing: border-box;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #F67676;
    line-height: 18px;
}

.rejectOrderPopup .modal-body p {
    margin-top: 20px;
}

@media screen and (max-width:768px) {
    .rejectOrderPopup {
        position: -webkit-sticky !important;
        position: sticky !important;
        bottom: 0 !important;
        left: 0 !important;
        z-index: 1055 !important;
        width: 100% !important;
        height: unset !important;
        overflow-x: unset !important;
        overflow-y: unset !important;
        outline: 0 !important;
    }

    .rejectOrderPopup .modal-dialog {
        margin: 0 !important;
    }

    .rejectOrderPopup .modal-content {
        border-radius: 0 !important;
    }

    .modal-sm {
        max-width: 100%;
    }
}
.textarea-container {
  height: 150px;
  width: 100%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  background: #fbfbfb;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
}

.textarea-container .left-icon {
  width: 16px;
}

.textarea-container .prefix-phone-number {
  width: 50px;
  height: 100%;
  line-height: 4 !important;
  color: #a9a9a9;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  border-right: 1px solid #eaeaea;
  padding-right: 10px;
  margin-left: 10px;
}

.textarea-container textarea {
  flex-grow: 1;
  width: 100%;
  background-color: transparent;
  border: none !important;
  outline: none !important;
  margin-left: 14px;
  margin-right: 14px;
  height: 100%;
  padding: 10px 0;
}

.textarea-container textarea:focus-within,
.textarea-container textarea:focus-visible,
.textarea-container textarea:hover,
.textarea-container textarea:focus {
  border: none !important;
}

.textarea-container textarea:read-only {
  color: #737373;
}

.textarea-container textarea::-webkit-input-placeholder {
  color: #a9a9a9;
}

.textarea-container textarea:-ms-input-placeholder {
  color: #a9a9a9;
}

.textarea-container textarea::placeholder {
  color: #a9a9a9;
}
.setting-page {
    padding: 10px
}

.setting-page .setting-basic-container .checkbox-container {
    background: #dadada !important;
}
.daily-report-container button.btn-print {
  border: 2px solid #f84e4e;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 13px 29px;
  color: #f84e4e;
  background-color: transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-color: black;
  color: black;
  margin-bottom: 10px;
}

.daily-report-table-container table {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  /* text-align: center; */
}

.daily-report-table-container table tfoot {
  background-color: #e5e5e5;
}

.daily-report-table-container table tfoot tr td span {
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
}
.daily-report-table-container table tfoot tr td span {
  font-weight: normal;
  font-size: 14px;
  color: #333333;
}
.daily-report-table-container table tfoot tr td span b {
  font-weight: normal;
  font-size: 14px;
  color: #7d7d7d;
}

.daily-report-table-container th,
td {
  padding: 12px;
}

.daily-report-table-container table thead tr th {
  color: #7d7d7d;
  font-size: 14px;
  font-weight: normal;
}

.daily-report-table-container table tbody tr td {
  color: #333333;
  font-size: 14px;
  font-weight: normal;
}

.daily-report-table-container table tr th:first-child,
.daily-report-table-container table tr td:first-child {
  border-right: 1px solid #f1f1f1;
}

.daily-report-table-container table tr th:last-child,
.daily-report-table-container table tr td:last-child {
  border-left: 1px solid #f1f1f1;
}

.daily-report-table-container table tr th,
.daily-report-table-container table tr td {
  border-bottom: 1px solid #f1f1f1;
}

.daily-report-table-container table tr:last-child td {
  border-bottom: none;
}

.daily-report-table-container table tr th:first-child,
.daily-report-table-container table tr td:first-child {
  text-align: left;
}

.daily-report-table-container table tbody tr td:first-child span {
  color: #7d7d7d;
  font-size: 10px;
  font-weight: normal;
}

.daily-report-total-count-mobile {
  padding: 0;
  padding-top: 10px;
  padding-right: 5px;
}

.daily-report-total-amount-mobile {
  padding: 0;
  padding-top: 10px;
  padding-left: 5px;
}

.daily-report-table-container table tfoot tr td {
  border: none !important;
}

.daily-report-page {
  padding: 10px;
}

.daily-report-page .daily-report-basic-container .checkbox-container {
  background: #dadada !important;
}

.language-page {
    padding: 10px
}

.language-page .language-basic-container .radio-container {
    background: #dadada !important;
}
